// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #1c1c1d;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

.bottom-nav .nav-item {
  text-align: center;
  flex: 1 1;
  padding: 10px 0;
  margin-top: -15px;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-nav .nav-item svg {
  margin-bottom: 5px;
}

.bottom-nav .nav-item.active {
  color: #dff73d;
}

.nav-item.disabled {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,yCAAyC;EACzC,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAO;EACP,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".bottom-nav {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 80px;\n  background-color: #1c1c1d;\n  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  z-index: 1;\n}\n\n.bottom-nav .nav-item {\n  text-align: center;\n  flex: 1;\n  padding: 10px 0;\n  margin-top: -15px;\n  color: #ffffff;\n  text-decoration: none;\n  font-size: 15px;\n  font-weight: 500;\n  transition: color 0.3s ease;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.bottom-nav .nav-item svg {\n  margin-bottom: 5px;\n}\n\n.bottom-nav .nav-item.active {\n  color: #dff73d;\n}\n\n.nav-item.disabled {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react'

import config from '../../../config'
import { useTelegram } from '../../../hooks/useTelegram'
import { Spinner } from '../../Spinner/Spinner'
import { Title } from './Icons'

import './Leaders.css'

const LeadersPage = () => {
  const { user } = useTelegram()

  const [leadersData, setLeadersData] = useState(null)
  const [userPosition, setUserPosition] = useState(null)

  useEffect(() => {
    const fetchLeadersData = async () => {
      setLeadersData(null)
      setUserPosition(0)
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/leaderboard`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()
        setLeadersData(result.topUsers)
        setUserPosition(result.userPosition)
      } catch (error) {
        console.error('Error fetching leaders data:', error)
      }
    }

    fetchLeadersData()
  }, [])

  const getPositionStyle = position => {
    switch (position) {
      case 1:
        return { color: '#FFE100' }
      case 2:
        return { color: '#999' }
      case 3:
        return { color: '#644307' }
      default:
        return { color: '#ffffff' }
    }
  }

  const getContent = () => {
    const requiredData = userPosition && leadersData
    if (!requiredData) return <Spinner />

    return (
      <>
        <div className="leaderboard-container">
          <div className="subtitle">Reach highest place, Drifter!</div>
          <div className="position-text">Your position in the leaderboard:</div>
          <div className="position">{userPosition}</div>
        </div>
        <div className="leaders-list">
          {leadersData.map(leader => {
            const isMe = leader.tgId === user.id
            return (
              <div key={leader.position}>
                <div className="leader-item">
                  <div className="leader-info">
                    <div className="leader-position">
                      <div style={getPositionStyle(leader.position)}>{leader.position}</div>
                      <div className={`leader-username ${isMe ? 'is-current-user' : ''}`}>
                        {leader.username.replace(/^@/, '')}
                      </div>
                    </div>
                    <div className="leader-points">{leader.points} Pts</div>
                  </div>
                </div>
                <div className="leader-divider"></div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="title">
        <Title />
      </div>
      {getContent()}
    </>
  )
}

export default LeadersPage

import React, { useRef } from 'react'

import { Close, ClaimedResultBackground } from '../../common/Icons'

export const ClaimedResult = ({ onClose }) => {
  const claimResultRef = useRef(null)

  const handleBackgroundClick = event => {
    if (claimResultRef.current && !claimResultRef.current.contains(event.target)) {
      onClose()
    }
  }

  return (
    <>
      <div className="blur-background-overlay" onClick={handleBackgroundClick}></div>

      <div className="daily-bonuses-claimed-background-container" ref={claimResultRef}>
        <div className="daily-bonuses-claimed-background">
          <ClaimedResultBackground color={'black'} />
        </div>
        <div className="daily-bonuses-claimed-text">You claimed energy and points from all your friends!</div>
        <div className="close-button" onClick={onClose}>
          <Close />
        </div>
      </div>
    </>
  )
}

import React, { useRef } from 'react'

import { Close, ClaimedResultBackground } from '../../common/Icons'

export const ClaimedResult = ({ isClaimAll = false, points = null, onClose }) => {
  const claimResultRef = useRef(null)

  const handleBackgroundClick = event => {
    if (claimResultRef.current && !claimResultRef.current.contains(event.target)) {
      onClose()
    }
  }

  const getClaimedText = () => {
    if (isClaimAll) return 'You claimed energy and points from all your friends!'
    if (points) return `Congrats you claimed ${points} pts from your friend`
    return 'Congrats you claimed 1 energy from your friend'
  }

  return (
    <>
      <div className="blur-background-overlay" onClick={handleBackgroundClick}></div>

      <div className="daily-bonuses-claimed-background-container" ref={claimResultRef}>
        <div className="daily-bonuses-claimed-background">
          <ClaimedResultBackground color={'black'} />
        </div>
        <div className="daily-bonuses-claimed-text">{getClaimedText()}</div>
        <div className="close-button" onClick={onClose}>
          <Close />
        </div>
      </div>
    </>
  )
}

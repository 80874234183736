// usePreloadImages.js
import { useState, useRef } from 'react';
import { 
    startLeftImgSrc, 
    startRightImgSrc, 
    finishImgSrcLeft, 
    finishImgSrcRight, 
    AdidasLeft, 
    ClipLeft, 
    defaultLeftSrc, 
    AdidasRight, 
    ClipRight, 
    defaultRightSrc,
    AdidasLeftLightSrc,
    AdidasRightLightSrc,
    ClipLeftLightSrc,
    ClipRightLightSrc
} from "./helpers.js"

export const usePreloadImages = () => {
  const [carImg, setCarImg] = useState(null);
  const [startImgLeft, setStartImgLeft] = useState(null);
  const [startImgRight, setStartImgRight] = useState(null);
  const [finishImgLeft, setFinishImgLeft] = useState(null);
  const [finishImgRight, setFinishImgRight] = useState(null);

  const [adidasLeftLight, setAdidasLeftLight] = useState(null);
  const [adidasRightLight, setAdidasRightLight] = useState(null);
  const [clipLeftLight, setClipLeftLight] = useState(null);
  const [clipRightLight, setClipRightLight] = useState(null);

  const [leftSegments, setLeftSegments] = useState([]);
  const [rightSegments, setRightSegments] = useState([]);

  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  const preloadImages = async (carImgSrc) => {
    try {
      const carImage = await loadImage(carImgSrc);
      const startImgLeft = await loadImage(startLeftImgSrc);
      const startImgRight = await loadImage(startRightImgSrc);
      const finishImgLeft = await loadImage(finishImgSrcLeft);
      const finishImgRight = await loadImage(finishImgSrcRight);

      const adidasLeftLightState = await loadImage(AdidasLeftLightSrc);
      const adidasRightLightState = await loadImage(AdidasRightLightSrc);
      const clipLeftLightState = await loadImage(ClipLeftLightSrc);
      const clipRightLightState = await loadImage(ClipRightLightSrc);

      const leftImg1 = {
        id: 0,
        type: "adidas",
        element: await loadImage(AdidasLeft),
      };
      const leftImg2 = {
        id: 1,
        type: "clip",
        element: await loadImage(ClipLeft),
      };
      const leftImg3 = {
        id: 10,
        type: "default",
        element: await loadImage(defaultLeftSrc),
      };
      setLeftSegments([leftImg1, leftImg2, leftImg3]);

      const rightImg1 = {
        id: 2,
        type: "adidas",
        element: await loadImage(AdidasRight),
      };
      const rightImg2 = {
        id: 3,
        type: "clip",
        element: await loadImage(ClipRight),
      };
      const rightImg3 = {
        id: 32,
        type: "default",
        element: await loadImage(defaultRightSrc),
      };
      setRightSegments([rightImg1, rightImg2, rightImg3]);

      setCarImg(carImage);
      setStartImgLeft(startImgLeft);
      setStartImgRight(startImgRight);
      setFinishImgLeft(finishImgLeft);
      setFinishImgRight(finishImgRight);

      setAdidasLeftLight(adidasLeftLightState);
      setAdidasRightLight(adidasRightLightState);
      setClipLeftLight(clipLeftLightState);
      setClipRightLight(clipRightLightState);

      setIsImagesLoaded(true);
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  return { 
    carImg, 
    startImgLeft, 
    startImgRight, 
    finishImgLeft, 
    finishImgRight, 
    leftSegments, 
    rightSegments, 
    isImagesLoaded, 
    preloadImages, 
    adidasLeftLight,
    adidasRightLight,
    clipLeftLight,
    clipRightLight
  };
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: absolute;
    padding: 10px;
    font-size: 20px;
    font-weight: 400;
    white-space: nowrap;
    pointer-events: none;
    animation: tooltip-animation 1s ease-out forwards;
    z-index: 1000;
    font-family: 'Benzin-Extrabold', sans-serif;
}

@keyframes tooltip-animation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;IACpB,iDAAiD;IACjD,aAAa;IACb,2CAA2C;AAC/C;;AAEA;IACI;QACI,UAAU;QACV,wBAAwB;IAC5B;;IAEA;QACI,UAAU;QACV,4BAA4B;IAChC;AACJ","sourcesContent":[".tooltip {\n    position: absolute;\n    padding: 10px;\n    font-size: 20px;\n    font-weight: 400;\n    white-space: nowrap;\n    pointer-events: none;\n    animation: tooltip-animation 1s ease-out forwards;\n    z-index: 1000;\n    font-family: 'Benzin-Extrabold', sans-serif;\n}\n\n@keyframes tooltip-animation {\n    0% {\n        opacity: 1;\n        transform: translateY(0);\n    }\n\n    100% {\n        opacity: 0;\n        transform: translateY(-20px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

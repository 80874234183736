import { useState } from 'react';

export const useTooltips = () => {
  const [tooltips, setTooltips] = useState([]);

  const addTooltip = (id, text, color, x, y, size) => {
    const newTooltip = { id, text, color, x, y, visible: true, size };
    
    setTooltips((prevTooltips) => [...prevTooltips, newTooltip]);

    setTimeout(() => {
      setTooltips((prevTooltips) => 
        prevTooltips.map((tooltip) => 
          tooltip.id === id ? { ...tooltip, visible: false } : tooltip
        )
      );
    }, 2000);

    setTimeout(() => {
      setTooltips((prevTooltips) => prevTooltips.filter((tooltip) => tooltip.id !== id));
    }, 2000);
  };

  return { tooltips, addTooltip };
};
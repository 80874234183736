import { useRef } from 'react';

export const useCarAngleHandler = () => {
  const carAngleRef = useRef(0);
  const lastClickTimeRef = useRef(0);
  const lastClickSideRef = useRef(null);
  const timeoutIdRef = useRef(null);
  const idLeftTimeout = useRef(0);
  const idRightTimeout = useRef(0);

 const handleLeftTurn = () => {
    carAngleRef.current = 50;
  };

  const handleRightTurn = () => {
    carAngleRef.current = -50;
  };

  const handleCanvasClick = (event, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const canvasMiddle = rect.width / 2;

    const now = Date.now();
    const side = clickX < canvasMiddle ? 'left' : 'right';

    // Проверяем, были ли два быстрых клика на одной стороне
    if (side === lastClickSideRef.current && now - lastClickTimeRef.current < 650) {
      if (!timeoutIdRef.current) {
        timeoutIdRef.current = setTimeout(() => {
          carAngleRef.current = 0;
          lastClickSideRef.current = null;
          timeoutIdRef.current = null;
        }, 650);
      }
    } else {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        carAngleRef.current = 0;
        lastClickSideRef.current = null;
        timeoutIdRef.current = null;
      }, 650);
    }

    lastClickTimeRef.current = now;
    lastClickSideRef.current = side;

    // Управляем поворотом в зависимости от стороны клика
    if (clickX < canvasMiddle) {
      handleLeftTurn();
      clearTimeout(idRightTimeout.current);
      clearTimeout(idLeftTimeout.current);
      idLeftTimeout.current = setTimeout(() => {
        carAngleRef.current = 0;
        clearTimeout(idLeftTimeout.current);
      }, 900);
    } else {
      handleRightTurn();
      clearTimeout(idLeftTimeout.current);
      clearTimeout(idRightTimeout.current);
      idRightTimeout.current = setTimeout(() => {
        carAngleRef.current = 0;
        clearTimeout(idRightTimeout.current);
      }, 900);
    }
  };

  return {
    handleCanvasClick,
    carAngleRef,
  };
};

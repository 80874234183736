import React, { useMemo, useState, useEffect } from 'react'

import { Light } from '../../common/Icons'
import { Circle, CompletedBackground, CheckIcon } from './Icons'

export const Bonus = ({ bonusItem }) => {
  const { day, bonus, loginStreak, claimedDays } = bonusItem

  const [isCompleted, setIsCompleted] = useState(false)
  const [isNotCompleted, setIsNotCompleted] = useState(false)

  const showPoints = useMemo(() => day < 7, [])
  const showEnergy = useMemo(() => day === 7, [])

  useEffect(() => {
    if (day <= loginStreak && loginStreak > claimedDays && day > claimedDays) setIsNotCompleted(true)
    else if (day <= claimedDays && day <= loginStreak) setIsCompleted(true)
  }, [])

  return (
    <>
      {day > 1 && (
        <div
          className="bonus-divider"
          style={{ background: isCompleted ? '#9AF73D' : isNotCompleted ? '#DFF73D' : '#f7f7f7' }}
        ></div>
      )}
      <div className="bonus-item">
        {isCompleted ? (
          <div className="complete-day-container">
            <div className="complete-day-icon-wrapper">
              <CompletedBackground />
              <div className="check-icon-container">
                <CheckIcon />
              </div>
            </div>
            <span className="bonus-description" style={{ color: '#9AF73D' }}>
              Day {day}
            </span>
          </div>
        ) : (
          <div className="circle-container">
            <div className="circle-with-day">
              <div className="circle">
                <Circle color={isNotCompleted ? '#DFF73D' : '#f7f7f7'} />
              </div>
              <span className="day-number" style={{ color: isNotCompleted ? '#DFF73D' : '#f7f7f7' }}>
                {day}
              </span>
            </div>
            <span className="bonus-description" style={{ color: isNotCompleted ? '#DFF73D' : '#f7f7f7' }}>
              Day {day}
            </span>
          </div>
        )}
        {showPoints && (
          <span
            className="bonus-points"
            style={{ color: isCompleted ? '#9AF73D' : isNotCompleted ? '#DFF73D' : '#f7f7f7' }}
          >
            {bonus} pts
          </span>
        )}
        {showEnergy && (
          <span className="bonus-energy-container">
            <span className="light-icon">
              <Light />
            </span>
            <span
              className="bonus-energy"
              style={{ color: isCompleted ? '#9AF73D' : isNotCompleted ? '#DFF73D' : '#f7f7f7' }}
            >
              {bonus}
            </span>
          </span>
        )}
      </div>
    </>
  )
}

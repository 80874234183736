// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/modal.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 8px;
    max-width: 305px;
    height: 241px;
    width: 100%;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-family: 'Benzin-Extrabold', sans-serif;
}

.resume {
    display: flex;
    justify-self: center;
}

.exit {
    margin-top: 10px;
    justify-self: center;
}

.modal-top {
    font-size: 16px;
    font-weight: 400;
}

.resume,
.exit {
    font-size: 24px;
    font-weight: 400;
}

.modal-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,yDAAkD;IAClD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.modal-content {\n    background-image: url('../../../assets/modal.svg');\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 305px;\n    height: 241px;\n    width: 100%;\n    position: relative;\n    color: white;\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    flex-direction: column;\n    font-family: 'Benzin-Extrabold', sans-serif;\n}\n\n.resume {\n    display: flex;\n    justify-self: center;\n}\n\n.exit {\n    margin-top: 10px;\n    justify-self: center;\n}\n\n.modal-top {\n    font-size: 16px;\n    font-weight: 400;\n}\n\n.resume,\n.exit {\n    font-size: 24px;\n    font-weight: 400;\n}\n\n.modal-group {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const Background = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="240" height="75" viewBox="0 0 282 84" fill="none">
    <path d="M0 9.6L282 0L268.571 84L22.381 75.6L0 9.6Z" fill="black" />
  </svg>
)

// yellow = "#DFF73D"
export const Circle = ({ color = '#F7F7F7' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="15" stroke={color} strokeWidth="2" />
  </svg>
)

export const CompletedBackground = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#9AF73D" />
  </svg>
)

export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.3327 4L5.99935 11.3333L2.66602 8"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

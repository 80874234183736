import React, { useRef } from 'react'

import { car_5 } from '../Cars/CarsImages/index'
import { Close, ClaimedResultBackground } from '../../common/Icons'
import { CarNameRectangle } from '../Cars/Icons'
import { ActionButton } from '../../ActionButton/ActionButton'
import { DAILY_BONUSES_ACTION_NAMES } from '../../../const'

export const ClaimedResult = ({ pointsNumber, day, onClose }) => {
  const claimResultRef = useRef(null)

  const close = () => {
    navigate('/')
  }

  const handleBackgroundClick = event => {
    if (claimResultRef.current && !claimResultRef.current.contains(event.target)) {
      onClose()
    }
  }

  return (
    <>
      <div className="blur-background-overlay" onClick={handleBackgroundClick}></div>
      {day < 21 ? (
        <>
          <div className="daily-bonuses-claimed-background-container" ref={claimResultRef}>
            <div className="daily-bonuses-claimed-background">
              <ClaimedResultBackground color={'black'} />
            </div>
            <div className="daily-bonuses-claimed-text">
            { day % 7 === 0
             ? `Congrats! You claimed 3 energy for a login bonus`
             : `Congrats! You claimed ${pointsNumber} pts for a login bonus`
            }
            </div>
            <div className="close-button" onClick={onClose}>
              <Close />
            </div>
          </div>
        </>
      ) : (
        <div className="daily-bonuses-congratulations-car">
          <div className="close-button" onClick={onClose}>
            <Close />
          </div>
          <div className="car-body">
            <div className="car-square-bonuses">
              <img src={car_5} alt="Car" className="car-image" />
              <div className="car-name-container">
                <div className="car-name-rectangle">
                  <CarNameRectangle />
                  <div className="car-name">Sliva</div>
                </div>
              </div>
            </div>
            <div className="daily-bonuses-claimed-text-car">
              <div className="claimed-bg-car"></div>
              <div className="claimed-bg-car-text">
                Congratulations! You got a new car after 21 days of continuous login!
              </div>
            </div>
            <div className="action-button-car">
              <ActionButton
                className="go-cars"
                actionName={DAILY_BONUSES_ACTION_NAMES.REDIRECT}
                actionCallback={close}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

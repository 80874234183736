import { car_1, car_2, car_3, car_5, car_6, car_7 } from './CarsImages'
import { CAR_NAMES } from '../../../const'

export const mapCarById = {
  [1]: car_7,
  [2]: car_1,
  [3]: car_6,
  [4]: car_5,
  [5]: car_3,
  [6]: car_2,
}

export const mapCarWithName = {
  [car_1]: CAR_NAMES.N2,
  [car_2]: CAR_NAMES.MISMO,
  [car_3]: CAR_NAMES.MONARCH,
  [car_5]: CAR_NAMES.SLIVA,
  [car_6]: CAR_NAMES.BRUNO,
  [car_7]: CAR_NAMES.KEIICHI_ROKU,
}

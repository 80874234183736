import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ActionButton } from '../../ActionButton/ActionButton'
import { Close } from '../../common/Icons'
import { Bonus } from './Bonus'
import { ClaimedResult } from './ClaimedResult'
import {
  DAILY_BONUSES_ACTION_NAMES,
  DAILY_BONUSES_ACTION_BUTTON_BACKGROUND_COLOR,
  DAILY_BONUSES_ACTION_BUTTON_TEXT_COLOR,
} from '../../../const'
import config from '../../../config'

import './DailyBonuses.css'

const DailyBonusesPage = () => {
  const navigate = useNavigate()

  const [isBlurred, setIsBlurred] = useState(false)
  const [isClaimed, setIsClaimed] = useState(false)
  const [dataDailyBonuses, setDataDailyBonuses] = useState([])
  const [pointsNumber, setPointsNumber] = useState(0)

  const dailyBonusesTemplate = [
      { day: 1, bonus: 100 },
      { day: 2, bonus: 200 },
      { day: 3, bonus: 300 },
      { day: 4, bonus: 400 },
      { day: 5, bonus: 500 },
      { day: 6, bonus: 600 },
      { day: 7, bonus: 3 },
    ];

  const claimDailyBonuses = async () => {
    const token = localStorage.getItem('accessToken')
    try {
      await fetch(`${config.serverBaseUrl}/bonuses/claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      console.error('Error during bonuses claim:', error)
    }
  }

  const handleClose = () => {
    setIsBlurred(false)
  }

  useEffect(() => {
    const fetchDailyBonuses = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/bonuses/progress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()
        const resultArray = dailyBonusesTemplate.map(item => ({
          ...item,
          ...result,
        }))
        setDataDailyBonuses(resultArray)
        setIsClaimed(result.claimedDays == result.loginStreak)
      } catch (error) {
        console.error('Error fetching daily bonuses data:', error)
      }
    }
    fetchDailyBonuses()
  }, [])

  const close = () => {
    navigate('/')
  }

  const onClaim = () => {
    if (!isClaimed) {
      setIsBlurred(true)
      setIsClaimed(true)
      const resultPoints = dataDailyBonuses.filter((el) => el.day <= el.loginStreak && el.loginStreak > el.claimedDays && el.day > el.claimedDays && el.loginStreak % 7 !== 0).reduce((sum, item) => sum + item.bonus, 0);
      const result = dataDailyBonuses.map((el) => {
       return {
        ...el,
        claimedDays: el.loginStreak
        };
      });
      setDataDailyBonuses(result);
      setPointsNumber(resultPoints);
      claimDailyBonuses();
    }
  }

  return (
    <>
      {isBlurred ? (
        <>
          <ClaimedResult pointsNumber={pointsNumber} onClose={handleClose} day={dataDailyBonuses[0]?.loginStreak * dataDailyBonuses[0]?.currentWeek} />
          <div className="blur-background-overlay"></div>
        </>
      ) : (
        <div className="daily-bonuses-page-container">
          <div className="daily-bonuses-title-background-container">
            <div className="daily-bonuses-title-background">
              <div className="daily-bonuses-title-text">Daily login bonuses week 1</div>
            </div>
            <div className="daily-bonuses-close-icon" onClick={close}>
              <Close />
            </div>
          </div>

          <div className="bonuses-list">
            {dataDailyBonuses.map(bonus => (
              <React.Fragment key={bonus.day}>
                <div className="bonus-wrapper">
                  <Bonus bonusItem={bonus} />
                </div>
              </React.Fragment>
            ))}
          </div>

          <div className="invite-friends-action-button">
            <ActionButton
              actionName={DAILY_BONUSES_ACTION_NAMES.CLAIM}
              actionCallback={onClaim}
              className="claim-button-bonuses"
              background={isClaimed ? DAILY_BONUSES_ACTION_BUTTON_BACKGROUND_COLOR.GREY : null}
              customTextColor={isClaimed ? DAILY_BONUSES_ACTION_BUTTON_TEXT_COLOR.GREY : null}
            />
          </div>

          <div className="daily-bonuses-bottom-container">
            <div className="daily-bonuses-bottom-background">
              <div className="daily-bonuses-bottom-text">
                <div>Login 3 weeks in a row</div>
                <div>
                  to get a <span className="new-car-text">New Car</span>!!!
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DailyBonusesPage

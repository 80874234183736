// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-button {
  display: flex;
  width: 76px;
  height: 32px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  background: #dff73d;
  border: none;
  cursor: pointer;
  gap: 5px;
}

.claim-button-text {
  font-family: 'Benzin-Medium', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.button-content {
  display: flex;
  align-items: center;
}

.spinner-button {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionButton/ActionButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,wCAAwC;EACxC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".claim-button {\n  display: flex;\n  width: 76px;\n  height: 32px;\n  padding: 6px 12px;\n  justify-content: center;\n  align-items: center;\n  background: #dff73d;\n  border: none;\n  cursor: pointer;\n  gap: 5px;\n}\n\n.claim-button-text {\n  font-family: 'Benzin-Medium', sans-serif;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-align: center;\n  display: inline-flex;\n  align-items: center;\n}\n\n.button-content {\n  display: flex;\n  align-items: center;\n}\n\n.spinner-button {\n  width: 16px;\n  height: 16px;\n  margin-right: 5px;\n  display: inline-flex;\n  align-items: center;\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

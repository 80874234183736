// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Syne:wght@400;500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/blacklisted);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-family: 'Syne', sans-serif;
  font-size: 14px;
  overflow: hidden;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -8;
  box-shadow: 0 3000px rgba(0, 0, 0, 0.2) inset;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -20;
  filter: contrast(110%);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,iCAAiC;EACjC,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500&display=swap');\n@import url('https://fonts.cdnfonts.com/css/blacklisted');\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  background: var(--tg-theme-bg-color);\n  color: var(--tg-theme-text-color);\n  font-family: 'Syne', sans-serif;\n  font-size: 14px;\n  overflow: hidden;\n}\n\n.background::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 90%;\n  background-image: inherit;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  z-index: -8;\n  box-shadow: 0 3000px rgba(0, 0, 0, 0.2) inset;\n}\n\n.background {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  z-index: -20;\n  filter: contrast(110%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './Tooltip.css';

const Tooltip = ({ tooltips }) => {
  return (
    <>
      {tooltips.map((tooltip) => (
        <div
          key={tooltip.id}
          className="tooltip"
          style={{
            left: `${tooltip.x}`,
            top: `${tooltip.y}`,
            color: `${tooltip.color}`,
            color: tooltip.color,
            opacity: tooltip.visible ? 1 : 0,
            fontSize: tooltip?.size,
          }}
        >
          {tooltip.text}
        </div>
      ))}
    </>
  );
};

export default Tooltip;

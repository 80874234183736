import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Cars, Leaders, Quests, Friends, Main } from './Icons'
import { MENU_ITEMS_COLOR } from '../../const'
import './Menu.css'

const Menu = ({ isAppReady }) => {
  const location = useLocation()

  const [activeIndex, setActiveIndex] = useState(-1)
  const [isDailyBonuses, setIsDailyBonuses] = useState(false)

  const navItems = [
    { name: 'Home', icon: <Main />, path: '/' },
    { name: 'Cars', icon: <Cars />, path: '/cars' },
    { name: 'Leaders', icon: <Leaders />, path: '/leaders' },
    { name: 'Quests', icon: <Quests />, path: '/quests' },
    { name: 'Friends', icon: <Friends />, path: '/friends' },
  ]

  useEffect(() => {
    const savedIndex = navItems.findIndex(item => item.path === location.pathname)
    if (savedIndex !== -1) {
      setActiveIndex(savedIndex)
    }

    if (location.pathname === '/dailyBonuses') {
      setIsDailyBonuses(true)
    } else {
      setIsDailyBonuses(false)
    }
  }, [location.pathname])

  const handleNavItemClick = index => {
    if (!isAppReady) return
    setActiveIndex(index)
    localStorage.setItem('activeIndex', index)
  }

  const isActive = index => {
    return activeIndex === index && !isDailyBonuses
  }

  const getElementColor = index => {
    return isActive(index) ? MENU_ITEMS_COLOR.YELLOW : MENU_ITEMS_COLOR.WHITE
  }

  return (
    <div className="bottom-nav">
      {navItems.map((item, index) => (
        <Link
          to={item.path}
          key={index}
          className={`nav-item ${isActive(index) ? 'active' : ''} ${!isAppReady ? 'disabled' : ''}`}
          onClick={() => handleNavItemClick(index)}
        >
          {React.cloneElement(item.icon, { color: getElementColor(index) })}
          <span>{item.name}</span>
        </Link>
      ))}
    </div>
  )
}

export default Menu

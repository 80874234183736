import { Car_1, Car_2, Car_3, Car_4, Car_5, Car_6 } from './cars'
import AdidasLeft from '../../../assets/races/adidas-left.svg'
import AdidasRight from '../../../assets/races/adidas-right.svg'
import ClipLeft from '../../../assets/races/clip-left.svg'
import ClipRight from '../../../assets/races/clip-right.svg'
import startLeftImgSrc from '../../../assets/races/start-left.svg'
import startRightImgSrc from '../../../assets/races/start-right.svg'
import finishImgSrcLeft from '../../../assets/races/finish-left.svg'
import finishImgSrcRight from '../../../assets/races/finish-right.svg'
import defaultLeftSrc from '../../../assets/races/default-left.svg'
import defaultRightSrc from '../../../assets/races/default-right.svg'
import pauseImg from '../../../assets/pause.svg'
import timerImg from '../../../assets/timer.svg'
import wheel from '../../../assets/races/wheel.gif'

import AdidasLeftLightSrc from '../../../assets/races/light/adidas-left-light.svg'
import AdidasRightLightSrc from '../../../assets/races/light/adidas-right-light.svg'
import ClipLeftLightSrc from '../../../assets/races/light/clip-left-light.svg'
import ClipRightLightSrc from '../../../assets/races/light/clip-right-light.svg'

import config from '../../../config'

export {
  AdidasLeft,
  AdidasRight,
  ClipLeft,
  ClipRight,
  startLeftImgSrc,
  startRightImgSrc,
  finishImgSrcLeft,
  finishImgSrcRight,
  defaultLeftSrc,
  defaultRightSrc,
  pauseImg,
  timerImg,
  wheel,
  AdidasLeftLightSrc,
  AdidasRightLightSrc,
  ClipLeftLightSrc,
  ClipRightLightSrc,
}

export const mapCarById = {
  [1]: Car_6,
  [2]: Car_1,
  [3]: Car_5,
  [4]: Car_4,
  [5]: Car_3,
  [6]: Car_2,
}

export function getCarImageById(id) {
  return mapCarById[id]
}

export function tooltipTextColor(scoreMultiplier) {
  if (scoreMultiplier === 1) return '#DFF73D'
  else if (scoreMultiplier === 2) return '#9AF73D'
  else if (scoreMultiplier === 3) return '#00B215'
  else if (scoreMultiplier === 4) return '#3DC5F7'
  else if (scoreMultiplier === 5) return '#0194DD'
  else if (scoreMultiplier === 6) return '#007AFF'
  else if (scoreMultiplier === 7) return '#F54DCB'
  else if (scoreMultiplier === 8) return '#DC2CB0'
  else if (scoreMultiplier === 9) return '#F21173'
  else if (scoreMultiplier === 10) return '#FA0E0E'
}

export const fetchStartGame = async () => {
  const token = localStorage.getItem('accessToken')
  try {
    await fetch(`${config.serverBaseUrl}/start-game`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (error) {
    console.error('Error fetching main page data:', error)
  }
}

export const fetchCar = async () => {
  const token = localStorage.getItem('accessToken')
  try {
    const response = await fetch(`${config.serverBaseUrl}/cars/current`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const result = await response.json()
    return getCarImageById(result.id)
  } catch (error) {
    console.error('Error fetching main page data:', error)
  }
}

export const fetchEndGame = async ptsScore => {
  const token = localStorage.getItem('accessToken')
  try {
    await fetch(`${config.serverBaseUrl}/finish-game`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ptsScore }),
    })
  } catch (error) {
    console.error('Error fetching main page data:', error)
  }
}

export const checkCollisionWithPaths = (paths, ctx, canvas, carAngleRef) => {
  const carX = canvas.width / 2 - canvas.carWidth / 2
  const carY = canvas.height / 2 - canvas.carHeight / 2 + (canvas.height * 15) / 100

  const angle = (carAngleRef * Math.PI) / 180

  const centerX = carX + canvas.carWidth / 2
  const centerY = carY + canvas.carHeight / 2

  const rotatedBottomLeft = {
    x: centerX + ((-canvas.carWidth / 2) * Math.cos(angle) - (canvas.carHeight / 2) * Math.sin(angle)),
    y: centerY + ((-canvas.carWidth / 2) * Math.sin(angle) + (canvas.carHeight / 2) * Math.cos(angle)),
  }
  const rotatedBottomRight = {
    x: centerX + ((canvas.carWidth / 2) * Math.cos(angle) - (canvas.carHeight / 2) * Math.sin(angle)),
    y: centerY + ((canvas.carWidth / 2) * Math.sin(angle) + (canvas.carHeight / 2) * Math.cos(angle)),
  }

  /*const square = new Path2D();
  square.rect(rotatedBottomLeft.x, rotatedBottomLeft.y, 20, 20);
  ctx.fillStyle = '#9AF73D';
  ctx.fill(square);

  const square1 = new Path2D();
  square1.rect(rotatedBottomRight.x, rotatedBottomRight.y, 20, 20);
  ctx.fillStyle = '#9AF73D';
  ctx.fill(square1);*/

  let result = false

  for (let path of paths) {
    if (
      ctx.isPointInPath(path, rotatedBottomLeft.x, rotatedBottomLeft.y) ||
      ctx.isPointInPath(path, rotatedBottomRight.x, rotatedBottomRight.y)
    ) {
      result = true
      break
    }
  }
  return result
}

export const drawCar = (ctx, canvas, carImg, angle) => {
  const carX = canvas.width / 2 - canvas.carWidth / 2
  const carY = canvas.height / 2 - canvas.carHeight / 2 + (canvas.height * 15) / 100

  /*ctx.save();
    ctx.translate(carX + canvas.carWidth / 2, carY + canvas.carHeight / 2);
    ctx.translate(0, -canvas.carHeight / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.translate(0, canvas.carHeight / 2);
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.filter = 'blur(10px)';
    ctx.fillRect(-canvas.carWidth / 2 + 10, -canvas.carHeight / 2 + 10, canvas.carWidth, canvas.carHeight);
    ctx.filter = 'none';
    ctx.restore();*/

  ctx.save()
  ctx.translate(carX + canvas.carWidth / 2, carY + canvas.carHeight / 2)
  ctx.translate(0, -canvas.carHeight / 2)
  ctx.rotate((angle * Math.PI) / 180)
  ctx.translate(0, canvas.carHeight / 2)
  ctx.drawImage(carImg, -canvas.carWidth / 2, -canvas.carHeight / 2, canvas.carWidth, canvas.carHeight)
  ctx.restore()
}

export const drawCarAtPosition = (ctx, segmentImg, segmentY, canvasWidth, canvasHeight, finish, finishY) => {
  ctx.drawImage(segmentImg, 0, segmentY, canvasWidth, canvasHeight)
  ctx.drawImage(finish, 0, finishY, canvasWidth, canvasHeight)
}

export function drawSegment(
  ctx,
  canvasWidth,
  canvasHeight,
  segmentY,
  xPercentage,
  yPercentage,
  heightPercentage,
  widthPercentage,
) {
  const segmentXOffset = xPercentage
  const segmentYOffset = yPercentage

  const square = new Path2D()
  const segmentHeight = (heightPercentage / 100) * canvasHeight
  const segmentWidth = (widthPercentage / 100) * canvasWidth
  square.rect(segmentXOffset, segmentY + segmentYOffset, segmentWidth, segmentHeight)

  ctx.globalAlpha = 0
  ctx.fillStyle = '#9AF73D'
  ctx.fill(square)
  ctx.globalAlpha = 1

  return square
}

export const getSegmentParams = (id, canvasWidth, canvasHeight) => {
  const adidasRightXStartPoint = Math.round((320 * canvasWidth) / 430) //362
  const adidasRightYStartPoint = Math.round((150 * canvasHeight) / 508) //215

  const adidasLeftXStartPoint = Math.round((21 * canvasWidth) / 430) //362
  const adidasLeftYStartPoint = Math.round((150 * canvasHeight) / 508) //215

  switch (id) {
    case 0:
      return {
        xPercentage: adidasLeftXStartPoint,
        yPercentage: adidasLeftYStartPoint,
        heightPercentage: 15,
        widthPercentage: 18,
      }
    case 2:
      return {
        xPercentage: adidasRightXStartPoint,
        yPercentage: adidasRightYStartPoint,
        heightPercentage: 15,
        widthPercentage: 18,
      }
    case 1:
      return {
        xPercentage: Math.round((5 / 100) * canvasWidth),
        yPercentage: Math.round((15 / 100) * canvasHeight),
        heightPercentage: 50,
        widthPercentage: 19,
      }
    case 3:
      return {
        xPercentage: Math.round((75 / 100) * canvasWidth),
        yPercentage: Math.round((15 / 100) * canvasHeight),
        heightPercentage: 50,
        widthPercentage: 19,
      }
    default:
      return {}
  }
}

export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

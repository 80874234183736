import React from 'react';

export const Timer = ({ remainingTime }) => {
  return (
    <div className='timer-text'>
      {remainingTime > 60 ? 
        `${Math.floor(remainingTime / 60) >= 10 ? Math.floor(remainingTime / 60) : `0${Math.floor(remainingTime / 60)}` } : ${Math.floor(remainingTime % 60) >= 10 ? Math.floor(remainingTime % 60) : `0${Math.floor(remainingTime % 60)}`  }` 
        : `00:${remainingTime >= 10 ? remainingTime : `0${remainingTime}`}` } sec
    </div>
  );
};

export const Score = ({ ptsScore }) => {
  return (
    <div className='score-text'>
      {ptsScore} pts
    </div>
  );
};
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import { BackgroundWrapper } from './BackgroundWrapper'

import Menu from './components/Menu/Menu'
import RacePage from './components/Pages/Races/Races'
import CarsPage from './components/Pages/Cars/CarsPage'
import LeadersPage from './components/Pages/Leaders/LeadersPage'
import QuestsPage from './components/Pages/Quests/QuestsPage'
import FriendsPage from './components/Pages/Friends/FriendsPage'
import MainPage from './components/Pages/Main/MainPage'
import DailyBonusesPage from './components/Pages/DailyBonuses/DailyBonusesPage'
import { useTelegram } from './hooks/useTelegram'

import './App.css'

function AppContent() {
  const location = useLocation()

  const [isAppReady, setIsAppReady] = useState(false)

  const isDrift = location.pathname === '/drift'

  return (
    <>
      <BackgroundWrapper>
        {!isDrift && <Menu isAppReady={isAppReady} />}
        <Routes>
          <Route path="/" exact element={<MainPage setAppReadyCallback={setIsAppReady} />} />
          <Route path="/dailyBonuses" element={<DailyBonusesPage />} />
          <Route path="/drift" element={<RacePage />} />
          <Route path="/cars" element={<CarsPage />} />
          <Route path="/leaders" element={<LeadersPage />} />
          <Route path="/quests" element={<QuestsPage />} />
          <Route path="/friends" element={<FriendsPage />} />
        </Routes>
      </BackgroundWrapper>
    </>
  )
}

export const App = () => {
  const { tg } = useTelegram()

  useEffect(() => {
    tg.ready()
    window.Telegram.WebApp.expand()
  }, [])

  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 30px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,qCAAqC;EACrC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  border: 3px solid rgba(0, 0, 0, 0.1);\n  border-left-color: rgb(255, 255, 255);\n  border-radius: 50%;\n  width: 25px;\n  height: 25px;\n  animation: spin 1s linear infinite;\n  margin: auto;\n  margin-top: 30px;\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

import { QUESTS_ACTION_BUTTON_BACKGROUND_COLOR, QUESTS_ACTION_BUTTON_TEXT_COLOR } from '../../const'
import { Spinner } from '../Spinner/Spinner'
import './ActionButton.css'

const _getTextColor = background => {
  switch (background) {
    case QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.BLACK:
      return QUESTS_ACTION_BUTTON_TEXT_COLOR.WHITE
    case QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.GREY:
      return QUESTS_ACTION_BUTTON_TEXT_COLOR.YELLOW
    default:
      return QUESTS_ACTION_BUTTON_TEXT_COLOR.BLACK
  }
}

export const ActionButton = ({
  actionName,
  background,
  customTextColor = null,
  actionCallback,
  className = '',
  isLoading = false,
}) => {
  const textColor = _getTextColor(background)

  return (
    <button
      className={`claim-button ${className}`}
      style={{
        background: background || QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.YELLOW,
        color: customTextColor || textColor,
      }}
      onClick={actionCallback}
      disabled={isLoading}
    >
      <div className="button-content">
        {isLoading && <Spinner customClassName="spinner-button" />}
        <span className={`claim-button-text ${className}`}>{actionName}</span>
      </div>
    </button>
  )
}

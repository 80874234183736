import React, { useState, useEffect, useRef } from 'react'

import config from '../../../config'
import { ActionButton } from '../../ActionButton/ActionButton'
import { Spinner } from '../../Spinner/Spinner'
import { Title, Close, Light } from '../../common/Icons'
import { ReferralLinkCopied } from './Icons'
import { ClaimedResult } from './ClaimedResult'
import {
  FRIENDS_ACTION_NAMES,
  FRIENDS_ACTION_BUTTON_BACKGROUND_COLOR,
  QUESTS_ACTION_BUTTON_TEXT_COLOR,
} from '../../../const'

import './Friends.css'

const { YELLOW, GREY } = FRIENDS_ACTION_BUTTON_BACKGROUND_COLOR

const FriendsPage = () => {
  const inviteDialogRef = useRef(null)

  const [loadingFriendId, setLoadingFriendId] = useState(null)
  const [friendsData, setFriendsData] = useState(null)
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [referralLinkCopied, setReferralLinkCopied] = useState(false)
  const [link, setLink] = useState('')
  const [isBlurred, setIsBlurred] = useState(false)
  const [claimedPoints, setClaimedPoints] = useState(null)
  const [isClaimAll, setIsClaimAll] = useState(false)

  const fetchFriends = async () => {
    setFriendsData(null)
    try {
      const token = localStorage.getItem('accessToken')
      const response = await fetch(`${config.serverBaseUrl}/friends`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()
      setFriendsData(result)
    } catch (error) {
      console.error('Error fetching leaders data:', error)
    }
  }

  const getReferralLink = async () => {
    try {
      const token = localStorage.getItem('accessToken')
      const response = await fetch(`${config.serverBaseUrl}/friends/referral-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()
      setLink(result)
    } catch (error) {
      console.error('Error during copying referral link:', error)
    }
  }

  const handleClaimResultClose = () => {
    setIsBlurred(false)
    setIsClaimAll(false)
    setClaimedPoints(null)
  }

  const handleInviteFriends = () => {
    setInviteDialogOpen(true)
  }

  const closeInviteModal = () => {
    setInviteDialogOpen(false)
  }

  const handleClickOutside = event => {
    if (inviteDialogRef.current && !inviteDialogRef.current.contains(event.target)) {
      closeInviteModal()
    }
  }

  useEffect(() => {
    fetchFriends()
    getReferralLink()
  }, [])

  useEffect(() => {
    if (inviteDialogOpen) {
      document.addEventListener('touchstart', handleClickOutside)
    } else {
      document.removeEventListener('touchstart', handleClickOutside)
    }

    return () => {
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [inviteDialogOpen])

  const claimAll = async () => {
    const token = localStorage.getItem('accessToken')
    try {
      const response = await fetch(`${config.serverBaseUrl}/friends/claim-all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()
      setFriendsData(result)
    } catch (error) {
      console.error('Error during claim all:', error)
    } finally {
      setIsClaimAll(true)
      setIsBlurred(true)
    }
  }

  const copyLink = async () => {
    const copyLinkAsync = async () => {
      try {
        if (navigator.clipboard) {
          const copyText = link
          const clipboardItem = new ClipboardItem({
            'text/plain': (async () => {
              try {
                if (!copyText) {
                  return new Promise(async resolve => {
                    resolve(new Blob(['']))
                  })
                }

                return new Promise(async resolve => {
                  const blob = new Blob([copyText], { type: 'text/plain' })
                  resolve(blob)
                })
              } catch (error) {
                console.error('Error creating Blob:', error)
                return new Promise(async resolve => {
                  resolve(new Blob(['']))
                })
              }
            })(),
          })
          await navigator.clipboard.write([clipboardItem])
        }
        closeInviteModal()
        setReferralLinkCopied(true)
        setTimeout(() => {
          setReferralLinkCopied(false)
        }, 5000)
      } catch (error) {
        console.error('Error during copying referral link:', error)
      }
    }
    await copyLinkAsync()
  }

  const sendLink = async () => {
    const sendLinkAsync = async () => {
      try {
        window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(link)}`)
        closeInviteModal()
      } catch (error) {
        console.error('Error during copying referral link:', error)
      }
    }
    await sendLinkAsync()
  }

  const claimEnergyOrPoints = async ({ isEnergyClaimed, friend }) => {
    const token = localStorage.getItem('accessToken')
    try {
      setLoadingFriendId(friend.tgId)
      if (isEnergyClaimed) {
        setClaimedPoints(friend.points)
      }

      const claimPath = isEnergyClaimed ? 'friends/claim-points' : 'friends/claim-energy'
      const response = await fetch(`${config.serverBaseUrl}/${claimPath}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ friendTgId: friend.tgId }),
      })
      const result = await response.json()
      setFriendsData(result)
    } catch (error) {
      console.error('Error claiming energy or points:', error)
    } finally {
      setLoadingFriendId(null)
      setIsBlurred(true)
    }
  }

  const getBackgroundColor = ({ isEnergyClaimed, friend }) => {
    if (loadingFriendId === friend.tgId) return GREY
    if (!isEnergyClaimed || (isEnergyClaimed && friend.points > 0)) return YELLOW
    return GREY
  }

  const getCustomTextColor = ({ isPointsClaimed, friend }) => {
    if (loadingFriendId === friend.tgId) return QUESTS_ACTION_BUTTON_TEXT_COLOR.GREY
    if (isPointsClaimed) return QUESTS_ACTION_BUTTON_TEXT_COLOR.GREY
    return null
  }

  const getContent = () => {
    if (!friendsData) {
      return <Spinner />
    }

    const { friends } = friendsData
    return (
      <>
        <div className="friends-container">
          <p className="friends-subtitle">Score 10% from friends.</p>
          <p className="friends-subtitle">Get 1 energy for each invited friend.</p>
        </div>
        <div className="invite-friends-action-container">
          <ActionButton
            actionName={FRIENDS_ACTION_NAMES.INVITE_FRIENDS}
            actionCallback={handleInviteFriends}
            className="invite-friends-action invite-friends-action-text"
          />
        </div>
        <div className="actions-container">
          <div className="friends-count">
            {friendsData.totalCount} {friendsData.totalCount === 1 ? 'Friend' : 'Friends'}
          </div>
          <div
            className={`claim-all-action claim-all-action-friends ${
              friendsData.totalCount === 0 ? 'no-friends' : ''
            }`}
            onClick={friendsData.totalCount > 0 ? claimAll : undefined}
          >
            Claim all
          </div>
        </div>

        <div className="friends-list">
          {friends.map((friend, index) => {
            const { isEnergyClaimed } = friend
            const isPointsClaimed = isEnergyClaimed && friend.points === 0
            return (
              <React.Fragment key={friend.tgId}>
                <div className="friend-container">
                  <div className="friend-info">
                    <div className="friend-description">
                      <span className="friend-name">{friend.username}</span>
                      <span className="friend-points">{friend.points} pts</span>
                    </div>
                    {!isEnergyClaimed && (
                      <div className="friend-energy-container">
                        <Light className="light-icon" />
                        <span className="friend-energy">1</span>
                      </div>
                    )}
                    <ActionButton
                      actionName={FRIENDS_ACTION_NAMES.CLAIM}
                      background={getBackgroundColor({ isEnergyClaimed, friend })}
                      customTextColor={getCustomTextColor({ isPointsClaimed, friend })}
                      actionCallback={() => claimEnergyOrPoints({ isEnergyClaimed, friend })}
                      isLoading={loadingFriendId === friend.tgId}
                    />
                  </div>
                </div>
                {index < friends.length - 1 && <div className="friends-divider"></div>}
              </React.Fragment>
            )
          })}
        </div>

        {inviteDialogOpen && (
          <div className="invite-modal-container">
            <div className="modal-base" ref={inviteDialogRef}>
              <div className="invite-friends-header-container">
                <div className="invite-friends-header">Invite friends</div>
                <div className="close-icon" onClick={closeInviteModal}>
                  <Close />
                </div>
              </div>
              <div className="modal-divider"></div>
              <div className="invite-actions-container">
                <ActionButton
                  actionName={FRIENDS_ACTION_NAMES.SEND}
                  className="invite-friends-action invite-friends-action-text invite-action-button"
                  actionCallback={sendLink}
                />
                <ActionButton
                  actionName={FRIENDS_ACTION_NAMES.COPY_LINK}
                  className="invite-friends-action invite-friends-action-text invite-action-button"
                  actionCallback={copyLink}
                />
              </div>
            </div>
          </div>
        )}

        {referralLinkCopied && (
          <div className="referral-link-copied">
            <ReferralLinkCopied />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {isBlurred ? (
        <ClaimedResult isClaimAll={isClaimAll} points={claimedPoints} onClose={handleClaimResultClose} />
      ) : (
        <div className="main-container">
          <div className={`blur-overlay ${inviteDialogOpen ? 'active' : ''}`}></div>
          <div className="title-container">
            <div className="rectangle-friends-background">
              <Title />
            </div>
            <div className="title-text">Friends</div>
          </div>
          {getContent()}
        </div>
      )}
    </>
  )
}

export default FriendsPage

import React, { useState, useEffect } from 'react'

import config from '../../../config'
import { Title, Check, CarNameRectangle, Locked } from './Icons'
import { Spinner } from '../../Spinner/Spinner'
import { mapCarById } from './helpers'
import './Cars.css'

const CarsPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userCars, setUserCars] = useState(null)
  const [currentCar, setCurrentCar] = useState(null)
  const [lockedCars, setLockedCars] = useState([]) // id of locked

  useEffect(() => {
    const loadImages = async () => {
      if (userCars) {
        const promises = userCars.map(
          car =>
            new Promise(resolve => {
              const img = new Image()
              img.src = car.imgSrc
              img.onload = () => resolve()
            }),
        )
        await Promise.all(promises)
        setIsLoading(false)
      }
    }
    loadImages()
  }, [userCars])

  useEffect(() => {
    const fetchCars = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/cars`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()

        const lockedCars = result.filter(el => !el.isUnlocked).map(el => el.id)

        const cars = result.map(({ id, name, isUnlocked }) => ({
          id,
          imgSrc: mapCarById[id],
          name,
          isUnlocked,
        }))
        cars.sort((a, b) => {
          return b.isUnlocked - a.isUnlocked
        })
        setUserCars(cars)
        setLockedCars(lockedCars)
      } catch (error) {
        console.error('Error fetching leaders data:', error)
      }
    }

    fetchCars()
  }, [])

  useEffect(() => {
    const fetchCurrentCar = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/cars/current`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()

        setCurrentCar(result)
      } catch (error) {
        console.error('Error fetching leaders data:', error)
      }
    }

    fetchCurrentCar()
  }, [])

  const handleCarClick = car => {
    if (!lockedCars.includes(car.id)) {
      setCurrentCar(car)
      const setNewCar = async () => {
        const token = localStorage.getItem('accessToken')
        try {
          await fetch(`${config.serverBaseUrl}/cars/change-current`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: car.id }),
          })
        } catch (error) {
          console.error('Error fetching leaders data:', error)
        }
      }
      setNewCar()
    }
  }

  const getContent = () => {
    if (isLoading || !userCars || !currentCar) {
      return <Spinner />
    }

    return (
      <div className="cars-grid">
        {userCars?.map((car, index) => {
          const isLocked = lockedCars.includes(car.id)
          const isSelected = currentCar.id === car.id
          return (
            <div
              key={index}
              className={`car-square ${isLocked ? 'locked' : ''}`}
              onClick={() => handleCarClick(car)}
            >
              {isSelected && !isLocked && (
                <div className="check-mark">
                  <Check />
                </div>
              )}
              <img src={car.imgSrc} alt={`Car ${index + 1}`} className="car-image" />
              <div className="car-name-container">
                <div className="car-name-rectangle">
                  <CarNameRectangle />
                  <div className={`car-name ${isSelected && 'selected-car'}`}>{car.name}</div>
                </div>
              </div>
              {isLocked && (
                <div className="locked-overlay">
                  <div className="locked-icon">
                    <Locked />
                  </div>
                  <div className="locked-text">Locked</div>
                  <div className="locked-description">
                    Invite friends, complete quests and log in every day to unlock
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <div className="title">
        <Title />
      </div>
      {getContent()}
    </>
  )
}

export default CarsPage

export const Title = () => (
  <svg width="164" height="49" viewBox="0 0 164 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5.6L164 0L156.19 49L13.0159 44.1L0 5.6Z" fill="black" />
  </svg>
)

export const Light = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2495 2H8.49395C8.31447 2 8.22473 2 8.14551 2.02733C8.07544 2.05149 8.01163 2.09093 7.95868 2.14279C7.89881 2.20143 7.85868 2.2817 7.77841 2.44223L3.57841 10.8422C3.38673 11.2256 3.29089 11.4173 3.31391 11.5731C3.33401 11.7091 3.40927 11.8309 3.52197 11.9097C3.65104 12 3.86534 12 4.29395 12H10.4995L7.49953 22L19.6926 9.35531C20.104 8.9287 20.3097 8.7154 20.3217 8.53288C20.3321 8.37446 20.2667 8.22049 20.1454 8.11803C20.0057 8 19.7094 8 19.1167 8H11.9995L14.2495 2Z"
      stroke="#DFF73D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18 6L6 18M6 6L18 18"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ClaimedResultBackground = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="302" height="192" viewBox="0 0 302 192" fill="none">
    <path d="M0 21.9429L302 0L287.619 192L23.9683 172.8L0 21.9429Z" fill={color} />
  </svg>
)
